@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/HKGrotesk-Light.d67a6435.woff) format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/HKGrotesk-Regular.f96cd5f2.woff) format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/HKGrotesk-Medium.9cd2d2ac.woff) format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/HKGrotesk-SemiBold.dc5781c1.woff) format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/HKGrotesk-Bold.20549ffa.woff) format('woff');
}

html {
  min-height: 100%;
  padding-bottom: 160px;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-family: "HKGrotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body-no-overflow {
  overflow: hidden;
}

.react-datepicker-popper {
  z-index: 2;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid rgb(75,75,75);
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

