@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/HKGrotesk-Light.woff') format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/HKGrotesk-Regular.woff') format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/HKGrotesk-Medium.woff') format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/HKGrotesk-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'HKGrotesk';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/HKGrotesk-Bold.woff') format('woff');
}

html {
  min-height: 100%;
  padding-bottom: 160px;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-family: "HKGrotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body-no-overflow {
  overflow: hidden;
}
